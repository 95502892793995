<script>
import Layout from "../../layouts/main";
import appConfig from "@/app.config";
import { authUserMethods } from "../../../state/helpers/authuser"
import draggable from 'vuedraggable';
/**
 * Blank page component
 */
export default {
  page: {
    title: "Team Members",
    meta: [{ name: "description", content: appConfig.description }]
  },
  display: "Transition",
  components: { Layout, draggable },
  data() {
    return {
      title: "Team Members",
      staffs: [],
      transProps: {
          // Transition name
          name: 'flip-list'
        },
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
 },
  mounted() {
    this.getAllManager()
  },
  methods: {
    ...authUserMethods,
    getAllManager() {
      return this.allStaffs({ is_visible_on_website: 1,  order_field: 'visible_on_website_position', ranking: 'asc'.toUpperCase() }).then((response) => {
        // eslint-disable-next-line no-console
        this.staffs = response.data
      })
    },
    getImage(items) {
      const image = items;
      if (image.length > 0) {
        return process.env.VUE_APP_STORAGE_PATH + image
      }
      return;
    },
     /* eslint-disable */
     handleImageAdded(event) {
      const newStaffList = this.staffs.map((item, index) => ({...item, visible_on_website_position: index}))

      this.updateVisibileToWebsitePosition({data: newStaffList}).then(() => {
        this.staffs = newStaffList
        this.getAllManager()
      })
      
    },
    handleImageRemoved(event) {
          // eslint-disable-next-line no-console
      console.log('...removed', event)
    },
    handleImageMoved(event) {
      /* eslint-disable */
      console.log('...moved', event)
    },
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-md-6">
        <b-card no-body class="mt-5" style="height:60vh">
          <b-card-body>
            <b-card-title class="d-flex mb-3 align-items-center justify-content-between">
              <h1 class="card-title">Team Members</h1>
              </b-card-title>
              <draggable v-model="staffs" v-bind="dragOptions" @change="handleImageAdded" @removed="handleImageRemoved" @moved="handleImageMoved" v-if="staffs.length > 0" >
                <transition-group type="transition" name="flip-list" class="team-members-card-view mb-5" >
                
                <div class="team-members-card mb-3" v-for="(staff, index) of staffs" :key="staff.id">
                     <img src="../../../assets/images/avatar.jpg" v-if="staff.avatar === null" />
                     <img alt="team member" class="img-fluid w-100"
                                        v-lazy="getImage(staff.avatar)" v-else />
                     <h4>{{staff.full_name}}</h4> 
                     <span v-if="staff.title !== null">{{staff.title}}</span> 
                     <span v-else><b>-</b></span>
                </div>
            </transition-group>
            </draggable>
            </b-card-body>
        </b-card>
      </div>
    </div>
  </Layout>
</template>

<style scoped>

.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

table#app-table .flip-list-move {
  transition: transform 1s;
}
</style>
